/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { isEmpty } from 'lodash';
import store from 'app/store';
import { logoutUser } from 'redux/authSlice';

const token = localStorage.getItem('token');

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
    'ngrok-skip-browser-warning': true,
    common: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  },
});

const generateErrorMessage = (error) => {
  let message = 'Something went wrong';
  console.log(error);
  if (error?.response?.data?.message) {
    message = error?.response?.data?.message;
  } else if (error?.response?.data?.error?.message) {
    message = error?.response?.error?.message;
  } else if (error?.data?.message) {
    message = error?.data?.message;
  } else if (error?.data?.error?.message) {
    message = error?.data?.error?.message;
  }
  return message;
};

const AxiosInterceptorWrapper = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const requestInterceptor = (config) => {
      return config;
    };

    const responseInterceptor = (response) => {
      if ('error' in response?.data && !isEmpty(response?.data?.error)) {
        enqueueSnackbar(generateErrorMessage(response), {
          autoHideDuration: 2500,
          variant: 'error',
        });

        return Promise.reject(response);
      }

      return response;
    };

    const errorInterceptor = (error) => {
      if (axios.isCancel(error)) {
        return;
      }
      if (!error?.config?.url.includes('file')) {
        enqueueSnackbar(generateErrorMessage(error), {
          autoHideDuration: 2500,
          variant: 'error',
        });
      }

      if (
        error?.response?.data?.error?.name === 'JWSInvalid' ||
        error?.response?.data?.error?.name === 'JWTExpired' ||
        error?.response?.data?.message?.includes('claim timestamp')
      ) {
        // COMMENT: redirect user to login page when token is invalid
        store.dispatch(logoutUser());
        window.location.replace(process.env.REACT_APP_SIGNOUT_REDIRECT_URL);
      }
      return Promise.reject(error);
    };

    const resInterceptor = axiosInstance.interceptors.response.use(
      responseInterceptor,
      errorInterceptor
    );
    const reqInterceptor =
      axiosInstance.interceptors.request.use(requestInterceptor);

    return () => {
      axiosInstance.interceptors.response.eject(resInterceptor);
      axiosInstance.interceptors.request.eject(reqInterceptor);
    };
  }, []);

  return children;
};

export default axiosInstance;
export { AxiosInterceptorWrapper };
