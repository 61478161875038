import Input from 'components/atoms/Input';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { BsEyeFill, BsEyeSlashFill } from 'react-icons/bs';
import { MaskInput, Mask } from 'maska';
import { Controller, useFormContext } from 'react-hook-form';

const InputForm = forwardRef(
  (
    {
      controllerName,
      className,
      label,
      wrapperClass,
      required = false,
      textArea = false,
      showErrorLabel = true,
      mask,
      maskaInputOptions,
      asText = false,
      asTextClass = '',
      showPrefix = false,
      prefix = '',
      disabled,
      onChangeInput,
      defaultValue,
      hidden = false,
      type = '',
      subfix = '',
      info = '',
      desc = '',
      ...props
    },
    ref
  ) => {
    const { control } = useFormContext();
    const inputRef = useRef();
    const maskValue = useRef();
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
      if (mask) {
        if (!asText) {
          new MaskInput(inputRef?.current || '[data-maska]', {
            mask,
            ...maskaInputOptions,
          });
        } else {
          maskValue.current = new Mask({
            mask,
            tokens: props?.['data-maska-tokens'] || undefined,
            ...maskaInputOptions,
          });
        }
      }
    }, [mask, asText, maskaInputOptions, props]);

    useImperativeHandle(ref, () => ({
      getInputRef: () => inputRef,
    }));

    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
      console.log(showPassword);
    };

    return (
      <Controller
        name={controllerName}
        control={control}
        defaultValue={defaultValue}
        render={({
          field: { onChange, value, onBlur },
          fieldState: { invalid, error },
        }) => {
          return (
            <div
              className={`form-control w-full ${wrapperClass || ''} ${
                hidden && 'hidden'
              }`}
            >
              <label className="flex gap-1 font-semibold text-[14px]">
                <span className={`label-text`}>{label}</span>
                {required && <span className="text-[#F04438]">*</span>}
              </label>
              <span className={`text-[#98A2B3] text-[12px]`}>{desc}</span>

              {asText ? (
                <div
                  className={[
                    'min-h-[2.5rem] py-2 overflow-hidden text-ellipsis whitespace-pre',
                    asTextClass,
                  ].join(' ')}
                >
                  {/* COMMENT: temporary workaround - check if value exists */}
                  {mask && maskValue.current && value
                    ? maskValue.current?.masked(value)
                    : value}
                  {subfix && subfix}
                </div>
              ) : undefined}

              {!asText && textArea ? (
                <textarea
                  ref={inputRef}
                  onChange={onChange}
                  {...props}
                  value={value}
                  className={`py-3 focus:outline-none focus:border-primary-700 ${
                    className ? className : 'input w-full max-w-xs border'
                  } ${invalid ? 'input-error' : 'input-bordered'} ${
                    invalid ? 'border-[#F04438]' : ''
                  }`}
                  disabled={disabled}
                />
              ) : undefined}
              {!asText && !textArea && (
                <>
                  <Input
                    prefix={prefix}
                    subfix={subfix}
                    ref={inputRef}
                    {...props}
                    type={
                      type !== 'password'
                        ? 'text'
                        : showPassword
                        ? 'text'
                        : 'password'
                    }
                    onChange={(e) => {
                      onChange(e);
                      if (onChangeInput) {
                        onChangeInput(e);
                      }
                    }}
                    value={value}
                    defaultValue={defaultValue}
                    data-maska={mask}
                    className={
                      className
                        ? `${className} ${invalid ? 'border-[#F04438]' : ''}`
                        : `input w-full max-w-xs border ${
                            invalid ? 'input-error' : 'input-bordered'
                          } ${invalid ? 'border-[#F04438]' : ''}`
                    }
                    rightIcon={
                      type === 'password' && (
                        <div
                          className="absolute inset-y-0 right-0 flex items-center pr-2 cursor-pointer"
                          onClick={togglePasswordVisibility}
                        >
                          {showPassword ? (
                            <BsEyeFill className="h-5 w-5 text-gray-500" />
                          ) : (
                            <BsEyeSlashFill className="h-5 w-5 text-gray-500" />
                          )}
                        </div>
                      )
                    }
                    disabled={disabled}
                  />
                </>
              )}

              <span className={`text-[#98A2B3] text-[12px]`}>{info}</span>
              {invalid && showErrorLabel && (
                <div className="text-xs text-error-600 mt-2 label-text-alt text-error text-[#F04438]">
                  {error?.message}
                </div>
              )}
            </div>
          );
        }}
      />
    );
  }
);

export default InputForm;
